import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ResearchPage from "./pages/ResearchPage";
import VenuesPage from "./pages/VenuesPage";
import InsurersPage from "./pages/InsurersPage";
import ArtistPage from "./pages/ArtistPage";
import LoginForm from "./components/LoginForm";
import Navbar from "./components/Navbar";

function App() {
  // Add state for managing the collapsed sidebar
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <Router>
      {/* Pass isCollapsed and setIsCollapsed to the Navbar */}
      <Navbar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />

      {/* Pass isCollapsed to each page component */}
      <Routes>
        <Route path="/" element={<HomePage isCollapsed={isCollapsed} />} />
        <Route
          path="/research"
          element={<ResearchPage isCollapsed={isCollapsed} />}
        />
        <Route
          path="/insurers"
          element={<InsurersPage isCollapsed={isCollapsed} />}
        />
        <Route
          path="/venues"
          element={<VenuesPage isCollapsed={isCollapsed} />}
        />
        <Route
          path="/artist"
          element={<ArtistPage isCollapsed={isCollapsed} />}
        />
        <Route path="/login" element={<LoginForm />} />
      </Routes>
    </Router>
  );
}

export default App;
