import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  MagnifyingGlassIcon, // This replaces SearchIcon
  BuildingOfficeIcon, // This replaces BuildingIcon
  UserGroupIcon,
  ShieldCheckIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline"; // Correct import for Heroicons v2

const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`h-screen bg-gradient-to-b from-white to-gray-100 border-r border-gray-200 ${
        isCollapsed ? "w-20" : "w-64"
      } fixed top-0 left-0 transition-width duration-300 flex flex-col justify-between`}
    >
      {/* Logo Section */}
      <div className="p-4 flex justify-between items-center">
        <img
          src="/howdenlogo.png"
          alt="Logo"
          className={`h-10 ${isCollapsed ? "hidden" : ""}`}
        />
        <button onClick={toggleSidebar} className="focus:outline-none">
          {isCollapsed ? (
            <ChevronRightIcon className="h-6 w-6 text-gray-600" />
          ) : (
            <ChevronLeftIcon className="h-6 w-6 text-gray-600" />
          )}
        </button>
      </div>

      {/* Navigation Links */}
      <nav className="flex-1 p-4 space-y-4">
        <Link
          to="/"
          className="flex items-center space-x-4 text-gray-700 hover:bg-gray-200 p-2 rounded-lg w-full transition"
        >
          <HomeIcon className="h-6 w-6 text-gray-500" />
          {!isCollapsed && <span>Home</span>}
        </Link>

        <Link
          to="/research"
          className="flex items-center space-x-4 text-gray-700 hover:bg-green-900 hover:text-white p-2 rounded-lg w-full transition"
        >
          <MagnifyingGlassIcon className="h-6 w-6 text-gray-500 hover:text-white" />
          {!isCollapsed && <span>Research</span>}
        </Link>

        <Link
          to="/venues"
          className="flex items-center space-x-4 text-gray-700 hover:bg-green-900 hover:text-white p-2 rounded-lg w-full transition"
        >
          <BuildingOfficeIcon className="h-6 w-6 text-gray-500 hover:text-white" />
          {!isCollapsed && <span>Venues</span>}
        </Link>

        <Link
          to="/insurers"
          className="flex items-center space-x-4 text-gray-700 hover:bg-green-900 hover:text-white p-2 rounded-lg w-full transition"
        >
          <ShieldCheckIcon className="h-6 w-6 text-gray-500 hover:text-white" />
          {!isCollapsed && <span>Insurers</span>}
        </Link>

        <Link
          to="/artist"
          className="flex items-center space-x-4 text-gray-700 hover:bg-green-900 hover:text-white p-2 rounded-lg w-full transition"
        >
          <UserGroupIcon className="h-6 w-6 text-gray-500 hover:text-white" />
          {!isCollapsed && <span>Artist/Band</span>}
        </Link>
      </nav>

      {/* Help Section */}
      <div className="p-4">
        <div className="flex items-center space-x-4 text-gray-700 hover:bg-gray-200 p-2 rounded-lg w-full">
          <QuestionMarkCircleIcon className="h-6 w-6 text-gray-500" />
          {!isCollapsed && (
            <div>
              <span>Need Help?</span>
              <p className="text-xs text-gray-500">Go to FAQ</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
