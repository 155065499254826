import React from "react";

const RecentClaims = ({ claims }) => {
  return (
    <div className="bg-white p-6 shadow-md rounded-md mb-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold mb-4">Recent Claims</h2>
        <a
          href="/recent-claims"
          className="text-sm text-blue-600 hover:text-blue-800"
        >
          View all
        </a>
      </div>
      <ul className="space-y-2">
        {claims.map((claim) => (
          <li key={claim.id} className="flex justify-between">
            <span className="text-gray-800 text-sm">{claim.name}</span>
            <span className="text-gray-500 text-sm">{claim.date}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentClaims;
