import React from "react";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { FaInstagram, FaTwitter } from "react-icons/fa"; // Import FontAwesome icons for Instagram and Twitter
import { SiMicrosoftsharepoint } from "react-icons/si"; // Import FontAwesome icon for SharePoint

const newsData = [
  {
    id: 1,
    image: "/edsheeran.jpeg",
    tags: ["Artist/Band", "Venue"],
    date: "23 March 2024",
    title: "Ed Sheeran's Concert Canceled",
    summary:
      "Ed Sheeran has canceled his concert due to unforeseen circumstances...",
    instagramLink: "https://www.instagram.com/teddysphotos/p/CVxGqZwI6S-/", // Only Instagram link
  },
  {
    id: 2,
    image: "/metricon.jpeg",
    tags: ["Venue"],
    date: "18 March 2024",
    title: "New Name for Metricon Stadium",
    summary:
      "Metricon Stadium will be renamed with new branding starting in 2023...",
    sharepointLink: "https://sharepoint.com/example", // Only SharePoint link
  },
  {
    id: 3,
    image: "/springmusic.jpeg",
    tags: ["Festival"],
    date: "12 April 2024",
    title: "Spring Music Festival Announced",
    summary:
      "The Spring Music Festival will take place in April after getting resch...",
    instagramLink: "https://www.instagram.com/teddysphotos/p/CVxGqZwI6S-/",
  },
  {
    id: 4,
    image: "/broadwayshow.jpeg",
    tags: ["Theater"],
    date: "5 May 2024",
    title: "Broadway Show Cancelled",
    summary:
      "The scheduled Broadway show has been canceled due to technical difficulties...",
    twitterLink: "https://x.com/Thebwayshowtv/status/1682480659044990977",
  },
  {
    id: 5,
    image: "/ufc.jpeg",
    tags: ["Sports", "Venue"],
    date: "20 June 2024",
    title: "Sports Event Rescheduled",
    summary:
      "The highly anticipated sports event has been rescheduled for next month...",
    howdenLink:
      "https://www.howdengroup.com/uk-en/news-insights/ai-unlimited-potential-or-unparalleled-risk", // Only Howden link
  },
  {
    id: 6,
    image: "/newalbum.jpeg",
    tags: ["Artist/Band"],
    date: "10 July 2024",
    title: "New Album Release",
    summary: "The artist has announced the release of a new album...",
    instagramLink: "https://x.com/Thebwayshowtv/status/1682480659044990977",
  },
];

const RecentNews = () => {
  return (
    <div className="bg-white p-4 md:p-6 shadow-md rounded-md mb-6 max-w-7xl mx-auto">
      <h2 className="text-lg md:text-xl font-bold mb-4 md:mb-6">Recent News</h2>
      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
        {newsData.map((item) => {
          // Determine source link (Instagram, Twitter, Howden, or SharePoint)
          const sourceLink =
            item.instagramLink ||
            item.twitterLink ||
            item.howdenLink ||
            item.sharepointLink;

          return (
            <li
              key={item.id}
              onClick={() => sourceLink && window.open(sourceLink, "_blank")}
              className="relative flex flex-col items-start p-4 rounded-lg shadow-md bg-blue-50 cursor-pointer transform transition duration-300 hover:scale-105 hover:shadow-2xl max-w-sm mx-auto"
              style={{ minHeight: "400px" }} // Ensure consistent card height
            >
              {/* Image */}
              <div className="w-full h-36 md:h-44 rounded-lg overflow-hidden mb-3">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-full object-contain"
                />
              </div>

              {/* Main content */}
              <div className="flex-1 w-full">
                {/* Tags and Date */}
                <div className="flex flex-wrap items-center space-x-2 mb-1 md:mb-2">
                  {item.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-700 text-xs md:text-sm font-semibold px-1.5 md:px-2 py-0.5 rounded"
                    >
                      {tag}
                    </span>
                  ))}
                  <span className="text-gray-500 text-xs md:text-sm">
                    {item.date}
                  </span>
                </div>

                {/* Title */}
                <p className="font-semibold text-base md:text-lg text-gray-900 mb-1">
                  {item.title}
                </p>

                {/* Summary */}
                <p className="text-xs md:text-sm text-gray-600 mb-3">
                  {item.summary}
                </p>
              </div>

              {/* Thumbs-up/Thumbs-down - Fixed position at the bottom left */}
              <div className="flex items-center space-x-3 mt-auto">
                <button
                  className="flex items-center space-x-1 text-gray-500 hover:text-green-600 transition duration-200"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <HandThumbUpIcon className="h-5 w-5" />
                </button>
                <button
                  className="flex items-center space-x-1 text-gray-500 hover:text-red-600 transition duration-200"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <HandThumbDownIcon className="h-5 w-5" />
                </button>
              </div>

              {/* Source Links - Move to bottom right */}
              <div className="absolute bottom-3 right-3 flex items-center space-x-2 md:space-x-4">
                {item.instagramLink && (
                  <a
                    href={item.instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-pink-600"
                  >
                    <span className="text-xs md:text-sm font-semibold mr-1">
                      Source:
                    </span>
                    <FaInstagram className="h-4 w-4 md:h-5 md:w-5" />
                  </a>
                )}
                {item.twitterLink && (
                  <a
                    href={item.twitterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-blue-400"
                  >
                    <span className="text-xs md:text-sm font-semibold mr-1">
                      Source:
                    </span>
                    <FaTwitter className="h-4 w-4 md:h-5 md:w-5" />
                  </a>
                )}
                {item.howdenLink && (
                  <a
                    href={item.howdenLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                  >
                    <img
                      src="/howdenlogo.png" // Replace with the actual path to the Howden logo in your public directory
                      alt="Howden"
                      className="h-4 w-auto md:h-5" // Adjust height and width to fit well within the card
                    />
                  </a>
                )}
                {item.sharepointLink && (
                  <a
                    href={item.sharepointLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-green-700"
                  >
                    <span className="text-xs md:text-sm font-semibold mr-1">
                      Source:
                    </span>
                    <SiMicrosoftsharepoint className="h-4 w-4 md:h-5 md:w-5" />
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecentNews;
