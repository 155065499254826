import React from "react";
import RecentNews from "../components/Home/RecentNews";
import SavedSearches from "../components/Home/SavedSearches";
import LatestPacks from "../components/Home/LatestPacks";
import RecentClaims from "../components/Home/RecentClaims";

// Sample data
const recentNews = [
  {
    id: 1,
    title: "Ed Sheeran's Concert Canceled",
    date: "23 March 2024",
    image: "image-url",
  },
  {
    id: 2,
    title: "New Venue for Music Festival",
    date: "18 March 2024",
    image: "image-url",
  },
];

const savedSearches = [
  { id: 1, name: "C3 Presents", date: "23 March 2024" },
  { id: 2, name: "Outside Theatre", date: "15 February 2024" },
];

const latestPacks = [
  { id: 1, name: "C3  Presents - Insurer Pitchbook", date: "23 March 2024" },
  { id: 2, name: "Ed Sheeran - Bucharest, RO", date: "23 March 2024" },
  { id: 3, name: "C3 Presents - Insurer Pitchbook", date: "23 March 2024" },
  { id: 4, name: "Ed Sheeran - Bucharest, RO", date: "23 March 2024" },
  { id: 5, name: "Ed Sheeran - Bucharest, RO", date: "23 March 2024" },
];

const recentClaims = [
  { id: 1, name: "Summer Fest 32", date: "23 March 2024" },
  { id: 2, name: "Dave Grohl - reschedule", date: "3 February 2024" },
];

const HomePage = () => {
  return (
    <div className="ml-64 p-8 bg-gray-200 min-h-screen">
      {" "}
      {/* Updated to bg-gray-300 */}
      {/* Header */}
      <div className="flex justify-between items-center mb-10">
        <h1 className="text-3xl font-bold">Welcome, John Marston</h1>
        <div className="flex items-center space-x-4">
          <button className="relative">
            <span className="absolute top-0 right-0 h-2 w-2 bg-red-500 rounded-full"></span>
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6 6 0 10-12 0v3.158c0 .538-.214 1.055-.595 1.437L4 17h5m4 0v1a3 3 0 11-6 0v-1m6 0H9"
              ></path>
            </svg>
          </button>
          <div className="flex items-center">
            <img
              className="h-10 w-10 rounded-full"
              src="/anujprofilepic.png"
              alt="Profile"
            />
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-700">John Marston</p>
              <p className="text-sm text-gray-500">Senior Broker</p>
            </div>
          </div>
        </div>
      </div>
      {/* Main content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <RecentNews news={recentNews} />
          <SavedSearches searches={savedSearches} />
        </div>
        <div className="space-y-8">
          <LatestPacks packs={latestPacks} />
          <RecentClaims claims={recentClaims} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
