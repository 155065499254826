import React from "react";

const SavedSearches = ({ searches }) => {
  return (
    <div className="bg-white p-4 md:p-6 shadow-md rounded-md mb-6 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg md:text-xl font-bold">Saved Searches</h2>
        <a
          href="/saved-searches"
          className="text-sm text-blue-600 hover:text-blue-800"
        >
          View all
        </a>
      </div>
      <ul className="space-y-2">
        {searches.map((search) => (
          <li key={search.id} className="flex justify-between">
            <span className="text-gray-800 text-sm">{search.name}</span>
            <span className="text-gray-500 text-sm">{search.date}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SavedSearches;
