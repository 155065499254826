// Correct: Default export
import React from "react";

const LoginForm = () => {
  return (
    <div>
      <h1>my login module will be here</h1>
    </div>
  );
};

export default LoginForm; // Default export
