import React from "react";

const ArtistPage = ({ isCollapsed }) => {
  return (
    <div
      className={`p-8 min-h-screen bg-gray-50 ${
        isCollapsed ? "ml-20" : "ml-64"
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold text-gray-800 mb-6">Artist Page</h1>
        {/* Page content goes here */}
      </div>
    </div>
  );
};

export default ArtistPage;
