import React, { useState } from "react";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { Line, Doughnut, PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
} from "chart.js";

// Register the required Chart.js elements
ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale
);

const ResearchPage = () => {
  const [researchType, setResearchType] = useState("");
  const [eventType, setEventType] = useState("");
  const [loading, setLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Simulate a delay to show loading spinner
    setTimeout(() => {
      setLoading(false);
      setShowReport(true); // Show the report after form submission
    }, 2000); // Simulate 2 seconds delay
  };

  const imagineDragonsReport = [
    {
      year: "2014",
      event: "Cancelled European Tour",
      reason: "Lead vocalist health issues",
      ticketsCancelled: 15000,
      refundAmount: 250000,
      city: "Paris",
      venue: "Stade de France",
      claimFiled: true,
      payoutStatus: "Paid",
      payoutAmount: 200000,
      eventDuration: 5,
      noticePeriod: 30,
    },
    {
      year: "2017",
      event: "Cancelled Japan Tour",
      reason: "Logistical challenges",
      ticketsCancelled: 10000,
      refundAmount: 200000,
      city: "Tokyo",
      venue: "Tokyo Dome",
      claimFiled: false,
      payoutStatus: "N/A",
      payoutAmount: 0,
      eventDuration: 3,
      noticePeriod: 10,
    },
    {
      year: "2019",
      event: "Cancelled New York Festival",
      reason: "Severe weather",
      ticketsCancelled: 25000,
      refundAmount: 500000,
      city: "New York",
      venue: "Central Park",
      claimFiled: true,
      payoutStatus: "Pending",
      payoutAmount: 0,
      eventDuration: 2,
      noticePeriod: 5,
    },
    {
      year: "2022",
      event: "Cancelled Las Vegas Concert",
      reason: "COVID-19 restrictions",
      ticketsCancelled: 30000,
      refundAmount: 750000,
      city: "Las Vegas",
      venue: "T-Mobile Arena",
      claimFiled: true,
      payoutStatus: "Paid",
      payoutAmount: 600000,
      eventDuration: 1,
      noticePeriod: 60,
    },
  ];

  // Color palette for a more cohesive look
  const colorPalette = {
    tickets: "rgba(66, 135, 245, 0.6)", // Blue
    refund: "rgba(211, 72, 54, 0.6)", // Red
    payout: "rgba(79, 195, 122, 0.6)", // Green
    claimFiled: "rgba(54, 162, 235, 0.6)", // Light Blue
    noClaim: "rgba(253, 189, 88, 0.6)", // Yellow
  };

  const areaChartData = {
    labels: imagineDragonsReport.map((item) => item.year),
    datasets: [
      {
        label: "Refund Amount ($)",
        data: imagineDragonsReport.map((item) => item.refundAmount),
        borderColor: "rgba(255, 99, 132, 0.8)",
        backgroundColor: "rgba(255, 99, 132, 0.3)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Tickets Cancelled",
        data: imagineDragonsReport.map((item) => item.ticketsCancelled),
        borderColor: colorPalette.tickets,
        backgroundColor: "rgba(66, 135, 245, 0.3)",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const claimsDistributionData = {
    labels: ["Claims Filed", "No Claims Filed"],
    datasets: [
      {
        label: "Claims Distribution",
        data: [
          imagineDragonsReport.filter((item) => item.claimFiled).length,
          imagineDragonsReport.filter((item) => !item.claimFiled).length,
        ],
        backgroundColor: [colorPalette.claimFiled, colorPalette.noClaim],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div className="ml-64 p-6 bg-gray-100 min-h-screen font-modern">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">New Research</h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white p-5 shadow-xl rounded-lg"
      >
        {/* Research Name */}
        <div className="mb-4">
          <label className="block text-gray-800 font-semibold mb-1 text-sm">
            Research Name
          </label>
          <input
            type="text"
            defaultValue="Imagine Dragons 2024"
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled
          />
        </div>

        {/* Research Type */}
        <div className="mb-4">
          <label className="block text-gray-800 font-semibold mb-1 text-sm">
            Research Type
          </label>
          <select
            value={researchType}
            onChange={(e) => setResearchType(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select research type</option>
            <option value="Event cancellation">Event cancellation</option>
            <option value="Non appearance">Non appearance</option>
            <option value="Event cancellation + Non appearance">
              Event cancellation + Non appearance
            </option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Event Type */}
        <div className="mb-4">
          <label className="block text-gray-800 font-semibold mb-1 text-sm">
            Event Type
          </label>
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select event type</option>
            <option value="Concert">Concert</option>
            <option value="Festival">Festival</option>
            <option value="Theater">Theater</option>
            <option value="Sports">Sports</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end items-center">
          <button
            type="submit"
            disabled={loading}
            className={`bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300 text-sm ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>

        {/* Loading Spinner */}
        {loading && <LoadingSpinner />}
      </form>

      {/* Imagine Dragons Report */}
      {showReport && (
        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4 text-gray-800">
            Imagine Dragons Event Cancellations (Past 10 Years)
          </h2>

          {/* Key Metrics */}
          <div className="grid grid-cols-3 gap-6 mb-8">
            <div className="bg-blue-50 p-6 rounded-lg shadow-md text-center">
              <h3 className="text-lg font-bold">Total Refund Amount</h3>
              <p className="text-3xl font-semibold text-blue-700">
                $
                {imagineDragonsReport
                  .reduce((acc, curr) => acc + curr.refundAmount, 0)
                  .toLocaleString()}
              </p>
            </div>
            <div className="bg-green-50 p-6 rounded-lg shadow-md text-center">
              <h3 className="text-lg font-bold">
                Average Notice Period (Days)
              </h3>
              <p className="text-3xl font-semibold text-green-700">
                {(
                  imagineDragonsReport.reduce(
                    (acc, curr) => acc + curr.noticePeriod,
                    0
                  ) / imagineDragonsReport.length
                ).toFixed(1)}
              </p>
            </div>
            <div className="bg-yellow-50 p-6 rounded-lg shadow-md text-center">
              <h3 className="text-lg font-bold">Total Claims Filed</h3>
              <p className="text-3xl font-semibold text-yellow-700">
                {imagineDragonsReport.filter((item) => item.claimFiled).length}
              </p>
            </div>
          </div>

          {/* Charts Section */}
          <div className="grid grid-cols-2 gap-6 mb-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Line
                data={areaChartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: 8,
                      padding: 10,
                    },
                  },
                }}
              />
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <Doughnut
                data={claimsDistributionData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    tooltip: {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      borderRadius: 8,
                      padding: 10,
                    },
                  },
                }}
              />
            </div>
          </div>

          {/* Data Table */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <table className="w-full table-auto text-left">
              <thead>
                <tr className="text-sm font-semibold text-gray-700 border-b">
                  <th className="p-2">Year</th>
                  <th className="p-2">Event</th>
                  <th className="p-2">Reason</th>
                  <th className="p-2">City</th>
                  <th className="p-2">Venue</th>
                  <th className="p-2">Tickets Cancelled</th>
                  <th className="p-2">Refund Amount</th>
                  <th className="p-2">Claim Filed</th>
                  <th className="p-2">Payout Status</th>
                  <th className="p-2">Payout Amount</th>
                  <th className="p-2">Event Duration (Days)</th>
                  <th className="p-2">Notice Period (Days)</th>
                </tr>
              </thead>
              <tbody>
                {imagineDragonsReport.map((item, index) => (
                  <tr
                    key={index}
                    className="text-sm text-gray-600 border-b hover:bg-gray-100 cursor-pointer transition"
                  >
                    <td className="p-2">{item.year}</td>
                    <td className="p-2">{item.event}</td>
                    <td className="p-2">{item.reason}</td>
                    <td className="p-2">{item.city}</td>
                    <td className="p-2">{item.venue}</td>
                    <td className="p-2">
                      {item.ticketsCancelled.toLocaleString()}
                    </td>
                    <td className="p-2">
                      ${item.refundAmount.toLocaleString()}
                    </td>
                    <td className="p-2">{item.claimFiled ? "Yes" : "No"}</td>
                    <td className="p-2">{item.payoutStatus}</td>
                    <td className="p-2">
                      ${item.payoutAmount.toLocaleString()}
                    </td>
                    <td className="p-2">{item.eventDuration}</td>
                    <td className="p-2">{item.noticePeriod}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResearchPage;
